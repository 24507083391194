import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import PortalVideoNew from '../Common/PortalVideoNew'
import PortalVideoUpdateNew from '../Common/PortalVideoUpdateNew';

const MarketingVideosSectionUpdate = () => {
  const data = useStaticQuery(graphql`
    query MarketingVideosSectionUpdateQuery {
      prismicSalesVideos { 
        data {
          section_title
          section_description {
            richText
          }
          vimeo_videos {
            video_description {
              richText
            }
            video_title
            video_type
            wistia_video_id
            vimeo_video_id
          }
        }
      }
    }
  `)

  const doc = data.prismicSalesVideos
  const videos = doc.data.vimeo_videos

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6">
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold'>{doc.data.section_title}</h2>
        {doc.data.section_description.richText
          ?
          <div className="mt-6">
            <RichText
              render={doc.data.section_description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }
      </div>

      <div className='w-full grid grid-cols-1 xl:grid-cols-2 gap-6 xl:gap-16 mt-8 xl:mt-16'>
        {videos.map(item => {
          return (
            item.video_type === 'Vimeo'
            ?
            <PortalVideoNew
              videoID={item.vimeo_video_id}
              videoTitle={item.video_title}
            >
              <RichText
                render={item.video_description.richText}
                htmlSerializer={htmlSerializer}
              />
            </PortalVideoNew>
            :
            <PortalVideoUpdateNew key={item.wistia_video_id} videoID={item.wistia_video_id} videoTitle={item.video_title}>
              <RichText render={item.video_description.richText} htmlSerializer={htmlSerializer} />
            </PortalVideoUpdateNew>
          )
        })}
      </div>      
    </>
  )
}

export default MarketingVideosSectionUpdate